import Certificate from "./pages/certificate";
import Experience from "./pages/experience";
import Footer from "./pages/footer";
import Header from "./pages/header";
import Navbar from "./pages/navbar";
import Services from "./pages/services";
import Skills from "./pages/skills";

import "./assets/js/bootstrap.bundle.min.js";
import Contact from "./pages/contact";
import AboutMe from "./pages/about";
import WhyChose from "./pages/whyChose";
import Portfolio from "./pages/portfolio";
import Testimonial from "./pages/testimonial";
import BackToTop from "./pages/back-to-top.js";
function App() {
  return (
    <>
      <Navbar />
      <Header />
      <AboutMe />
      <Services />
      <Portfolio />
      <WhyChose />
      <Experience />
      {/* <Skills /> */}
      <Testimonial />
      <Certificate />
      <Contact />
      <Footer />
      <BackToTop />
    </>
  );
}

export default App;
