// import * as WOW from "./wow.js";
import { WOW } from "wowjs";
const customJs = () => {
  const MenuButton = document.querySelector(".navbar-toggler");
  const nonesc = document.querySelector(".none-sc");

  let showMenu = false;

  MenuButton.addEventListener("click", toggleMenu);
  function toggleMenu() {
    if (!showMenu) {
      body.classList.add("none-sc");
      showMenu = true;
    } else {
      body.classList.remove("none-sc");
      showMenu = false;
    }
  }

  const body = document.body;
  const triggerMenu = document.querySelector(".top-h-section");
  const scrollUp = "sc-up";
  // const scrollDown = "sc-down";
  const scrollDown = "sc-up";

  let lastScroll = 0;
  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  });

  new WOW().init();
  // $(".reset").click(function () {
  //   new WOW().init();
  // });

  const navbar = document.querySelector(".navbar");
  const navbarLinks = navbar.querySelectorAll(".nav-item a");

  navbarLinks.forEach((link) => {
    link.addEventListener("click", () => {
      if (window.innerWidth <= 768) {
        const navbarCollapse = navbar.querySelector(".navbar-collapse");
        if (navbarCollapse.classList.contains("show")) {
          navbarCollapse.classList.remove("show");
        }
      }
    });
  });

  const button = document.querySelector(".btn-top");

  const displayButton = () => {
    window.addEventListener("scroll", () => {
      console.log(window.scrollY);

      if (window.scrollY > 500) {
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    });
  };

  displayButton();
};

export default customJs;
